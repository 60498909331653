import { useState, useEffect } from "react";
import Wallet from "./wallet";

export default function WalletsList({handleWalletClick, walletsList, walletsSearchList, isWalletSearch}){

    const walletsListToShow = isWalletSearch ? walletsSearchList : walletsList;

    // console.log(isWalletSearch);

    return(
            walletsListToShow.length > 0 ? 
            <div className="wallets-list">
                {walletsListToShow.map(wallet=>
                    <Wallet key={wallet.id} walletName={wallet.name} walletIcon={wallet.image_url.md} onClick={()=>handleWalletClick(walletsList.find(currWallet=>currWallet.id == wallet.id))} />
                )
            }
           </div>
           :
           <div className="empty-wallets-list">
                <div className="empty-wallets-list-icon">
                    <svg fill="none" viewBox="0 0 20 20">
                        <path fill="currentColor" fill-rule="evenodd" d="M0 5.5c0-1.8 1.46-3.25 3.25-3.25H14.5c1.8 0 3.25 1.46 3.25 3.25v.28A3.25 3.25 0 0 1 20 8.88v2.24c0 1.45-.94 2.68-2.25 3.1v.28c0 1.8-1.46 3.25-3.25 3.25H3.25A3.25 3.25 0 0 1 0 14.5v-9Zm15.75 8.88h-2.38a4.38 4.38 0 0 1 0-8.76h2.38V5.5c0-.69-.56-1.25-1.25-1.25H3.25C2.56 4.25 2 4.81 2 5.5v9c0 .69.56 1.25 1.25 1.25H14.5c.69 0 1.25-.56 1.25-1.25v-.13Zm-2.38-6.76a2.37 2.37 0 1 0 0 4.75h3.38c.69 0 1.25-.55 1.25-1.24V8.87c0-.69-.56-1.24-1.25-1.24h-3.38Z" clip-rule="evenodd"></path>
                    </svg>
                </div>
                <div className="empty-wallets-list-text">No Wallet Found</div>
            </div>
    )
}