export default function PageHead({closeButtonAction, backButtonAction, title}){

    return(
        <div className="modal-page-head">
            <button className="modal-page-head-button" onClick={backButtonAction}>
                <svg fill="none" viewBox="0 0 16 16">
                    <path fill="#ffffff" fillRule="evenodd" d="M11.04 1.46a1 1 0 0 1 0 1.41L5.91 8l5.13 5.13a1 1 0 1 1-1.41 1.41L3.79 8.71a1 1 0 0 1 0-1.42l5.84-5.83a1 1 0 0 1 1.41 0Z" clipRule="evenodd"></path>
                </svg>
            </button>
            <div className="modal-page-head-title">{title || 'All Wallets'}</div>
            <button className="modal-page-head-button" onClick={closeButtonAction}>
                <svg fill="none" viewBox="0 0 16 16">
                    <path fill="#ffffff" fillRule="evenodd" d="M2.54 2.54a1 1 0 0 1 1.42 0L8 6.6l4.04-4.05a1 1 0 1 1 1.42 1.42L9.4 8l4.05 4.04a1 1 0 0 1-1.42 1.42L8 9.4l-4.04 4.05a1 1 0 0 1-1.42-1.42L6.6 8 2.54 3.96a1 1 0 0 1 0-1.42Z" clipRule="evenodd"></path>
                </svg>
            </button>
        </div>
    )
}