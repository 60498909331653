// import logoSvg from './../logo.svg'

import { useRef } from "react";

export default function Wallet({walletName, walletIcon, onClick}){

    const wallet = useRef()

    function removeShimmer(){
        wallet.current.classList.remove('shimmer');
    }
    return(
        <div className="wallet" onClick={onClick}>
            <img ref={wallet} className='wallet-icon shimmer' src={walletIcon} onLoad={removeShimmer} loading="lazy"/>
            <div className='wallet-name'>{walletName}</div>
        </div>
    )
}