import DeviceType from "./wallet-connect/device-type";
import PageHead from "./page-head";
import WalletLoading from "./wallet-connect/wallet-loading";
import { useState } from "react";

export default function WalletConnectPage({backButtonAction, closeButtonAction, walletDetails, handleManualClick}){

    const [isBrowser, setIsBrowser] = useState(false)

    return(
        <div className="wallet-connect-page">
            <PageHead backButtonAction={backButtonAction} closeButtonAction={closeButtonAction} title={walletDetails.name} />
            <div className="wallet-connect-body">
                <DeviceType isBrowser={isBrowser} setIsBrowser={setIsBrowser} />
                <WalletLoading walletDetails={walletDetails} handleManualClick={handleManualClick} isBrowser={isBrowser}/>
            </div>
        </div>
    )
}