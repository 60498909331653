import { useEffect, useState } from "react"

export default function WalletLoading({walletDetails, handleManualClick, isBrowser}){

    const [hasFailed, setHasFailed] = useState(false)

    function connect(){
        setHasFailed(false)
        setTimeout(()=>{
            setHasFailed(true);
            // console.log('test')
        }, 2000 + 2000*Math.random())
    }

    useEffect(()=>{
        connect()
    }, [])

    return(
        <>
            { isBrowser ? 
                <>
                <div className="wallet-loading">
                    {hasFailed || <div className="connecting-animation">
                            <svg  className="connecting" viewBox="0 0 110 110" width="110" height="110">
                            <rect x="2" y="2" width="106" height="106" rx="36" strokeDasharray="116 245" strokeDashoffset="360"></rect>
                        </svg>
                    </div>}
                    <img className="selected-wallet-icon" src={walletDetails.image_url}/>
                </div>
                {hasFailed && <div className="continue-text">
                    Not Detected
                </div>}
                {hasFailed &&
                    <div className="connect-manually" onClick={handleManualClick}>
                        <span>Connect Manually</span>
                    </div>
                }
                </>
                :
                <>
                <div className="wallet-loading">
                    {hasFailed || <div className="connecting-animation">
                            <svg  className="connecting" viewBox="0 0 110 110" width="110" height="110">
                            <rect x="2" y="2" width="106" height="106" rx="36" strokeDasharray="116 245" strokeDashoffset="360"></rect>
                        </svg>
                    </div>}
                    <img className="selected-wallet-icon" src={walletDetails.image_url}/>
                    {hasFailed && <div className="connect-failed-icon">
                        <svg fill="none" viewBox="0 0 16 16">
                            <path fill="#f25a67" fill-rule="evenodd" d="M2.54 2.54a1 1 0 0 1 1.42 0L8 6.6l4.04-4.05a1 1 0 1 1 1.42 1.42L9.4 8l4.05 4.04a1 1 0 0 1-1.42 1.42L8 9.4l-4.04 4.05a1 1 0 0 1-1.42-1.42L6.6 8 2.54 3.96a1 1 0 0 1 0-1.42Z" clip-rule="evenodd"></path>
                        </svg>
                    </div>}
                </div>
                {hasFailed ?
                    <>
                        <div className="continue-text error-text">
                            Connection failed
                        </div>
                        <div className="accept-text">
                            Connection was declined or failed. You can connect your wallet manually
                        </div>
                    </>
                    :
                    <>
                        <div className="continue-text">
                            Continue in {walletDetails.name}
                        </div>
                        <div className="accept-text">
                            Accept connection request in the wallet.
                        </div>
                    </>
                }
                {hasFailed && <>
                    <div className="connect-manually" onClick={handleManualClick}>
                        <span>Connect Manually</span>
                    </div>
                    <div className="connect-manually" onClick={connect} >
                        <svg fill="none" viewBox="0 0 14 16">
                            <path fill="currentColor" fill-rule="evenodd" d="M3.94 1.04a1 1 0 0 1 .7 1.23l-.48 1.68a5.85 5.85 0 0 1 8.53 4.32 5.86 5.86 0 0 1-11.4 2.56 1 1 0 0 1 1.9-.57 3.86 3.86 0 1 0 1.83-4.5l1.87.53a1 1 0 0 1-.55 1.92l-4.1-1.15a1 1 0 0 1-.69-1.23l1.16-4.1a1 1 0 0 1 1.23-.7Z" clip-rule="evenodd"></path>
                        </svg>
                        <span>Try again</span>
                    </div>
                    </>
                }
                { hasFailed || <div className="copy-link">
                    <svg fill="none" viewBox="0 0 16 16">
                        <path fill="currentColor" fill-rule="evenodd" d="M9.5 0h1.67c.68 0 1.26 0 1.73.04.5.05.97.14 1.42.4.52.3.95.72 1.24 1.24.26.45.35.92.4 1.42.04.47.04 1.05.04 1.73V6.5c0 .69 0 1.26-.04 1.74-.05.5-.14.97-.4 1.41-.3.52-.72.95-1.24 1.25-.45.25-.92.35-1.42.4-.43.03-.95.03-1.57.03 0 .62 0 1.14-.04 1.57-.04.5-.14.97-.4 1.42-.29.52-.72.95-1.24 1.24-.44.26-.92.35-1.41.4-.48.04-1.05.04-1.74.04H4.83c-.68 0-1.26 0-1.73-.04-.5-.05-.97-.14-1.42-.4-.52-.3-.95-.72-1.24-1.24a3.39 3.39 0 0 1-.4-1.42A20.9 20.9 0 0 1 0 11.17V9.5c0-.69 0-1.26.04-1.74.05-.5.14-.97.4-1.41.3-.52.72-.95 1.24-1.25.45-.25.92-.35 1.42-.4.43-.03.95-.03 1.57-.03 0-.62 0-1.14.04-1.57.04-.5.14-.97.4-1.42.29-.52.72-.95 1.24-1.24.44-.26.92-.35 1.41-.4A20.9 20.9 0 0 1 9.5 0ZM4.67 6.67c-.63 0-1.06 0-1.4.03-.35.03-.5.09-.6.14-.2.12-.38.3-.5.5-.05.1-.1.24-.14.6C2 8.32 2 8.8 2 9.54v1.59c0 .73 0 1.22.03 1.6.04.35.1.5.15.6.11.2.29.38.5.5.09.05.24.1.6.14.37.03.86.03 1.6.03h1.58c.74 0 1.22 0 1.6-.03.36-.04.5-.1.6-.15.2-.11.38-.29.5-.5.05-.09.1-.24.14-.6.03-.33.03-.76.03-1.39-.6 0-1.13 0-1.57-.04-.5-.04-.97-.14-1.41-.4-.52-.29-.95-.72-1.25-1.24a3.39 3.39 0 0 1-.4-1.41c-.03-.44-.03-.96-.03-1.57Zm3.27-4.64c-.36.04-.5.1-.6.15-.2.11-.38.29-.5.5-.05.09-.1.24-.14.6-.03.37-.03.86-.03 1.6v1.58c0 .74 0 1.22.03 1.6.03.36.09.5.14.6.12.2.3.38.5.5.1.05.24.1.6.14.38.03.86.03 1.6.03h1.59c.73 0 1.22 0 1.6-.03.35-.03.5-.09.6-.14.2-.12.38-.3.5-.5.05-.1.1-.24.14-.6.03-.38.03-.86.03-1.6V4.87c0-.73 0-1.22-.03-1.6a1.46 1.46 0 0 0-.15-.6c-.11-.2-.29-.38-.5-.5-.09-.05-.24-.1-.6-.14-.37-.03-.86-.03-1.6-.03H9.55c-.74 0-1.22 0-1.6.03Z" clip-rule="evenodd"></path>
                    </svg>
                    <span>Copy Link</span>
                </div>}
            </>
            }
            <div className="get-wallet">
                <span>Don't have {walletDetails.name}?</span>
                <div className="get-wallet-button">
                    <span>Get</span>
                    <svg fill="none" viewBox="0 0 16 16">
                        <path fill="currentColor" fill-rule="evenodd" d="M4.96 14.54a1 1 0 0 1 0-1.41L10.09 8 4.96 2.87a1 1 0 0 1 1.41-1.41l5.84 5.83a1 1 0 0 1 0 1.42l-5.84 5.83a1 1 0 0 1-1.41 0Z" clip-rule="evenodd"></path>
                    </svg>
                </div>
            </div>
        </>
    )
}