import PageHead from "./page-head";
import SearchWallets from "./pick-wallet/search";
import WalletsList from './pick-wallet/wallets-list'

export default function PickWalletPage({closeButtonAction, handleWalletClick, walletsList, walletsSearchList,handleSearch, isWalletSearch, searchString}){

    return(
        <div className="pick-wallet-page">
            <PageHead closeButtonAction={closeButtonAction} backButtonAction={closeButtonAction} />
            <SearchWallets handleSearch={handleSearch} isWalletSearch={isWalletSearch} searchString={searchString}/>
            <WalletsList handleWalletClick={handleWalletClick} walletsList={walletsList} walletsSearchList={walletsSearchList} isWalletSearch={isWalletSearch} />
        </div>
    )
}