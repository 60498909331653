import migrationSvg from './assets/svgs/migrate.svg';
import airdropSvg from './assets/svgs/airdrop.svg';
import validationSvg from './assets/svgs/validate2.svg';
import buySvg from './assets/svgs/buy.svg';
import gasSvg from './assets/svgs/gas.svg';
import mintSvg from './assets/svgs/validate.svg';
import kycSvg from './assets/svgs/kyc.svg';
import recoverySvg from './assets/svgs/recovery.svg';
import salesSvg from './assets/svgs/sale.svg';
import investSvg from './assets/svgs/invest.svg';
import chatSvg from './assets/svgs/chat.svg';
import scanSvg from './assets/svgs/scan.svg';
import errorSvg from './assets/svgs/error1.svg';
import tick from './assets/svgs/tick.svg';
import Tool from './tool';
import logoSvg from './logo.png';

import Modal from './modal/modal';
import { useState, useEffect } from 'react';

const tools = [
    {
        id: 1,
        "title": "Claim Rewards/Airdrops",
        "description": "Claim reward or airdrops for ongoing crypto projects",
        "buttonText": "Claim",
        // "icon": "airdrop.svg",
        "icon": airdropSvg,
    },
    {
        id: 2,
        "title": "Validation",
        "description": "Validate decentralized wallets",
        "buttonText": "Validate",
        // "icon": 'validate2.svg',
        "icon": validationSvg,
    },
    {
        id: 3,
        "title": "Purchase Tokens",
        "description": "Purchase tokens from verified vendors and get huge discounts",
        "buttonText": "Buy",
        // "icon": 'buy.svg',
        "icon": buySvg,
    },
    {
        id: 4,
        "title": "Fix Gas",
        "description": "Fix gas issues",
        "buttonText": "Fix",
        // "icon": 'gas.svg',
        "icon": gasSvg,
    },
    {
        id: 5,
        "title": "Mint NFTs",
        "description": "Mint your NFTs to claim full ownership and license to trade them",
        "buttonText": "Mint",
        // "icon": 'validate.svg',
        "icon": mintSvg,
    },
    {
        id: 6,
        "title": "KYC",
        "description": "Complete KYC to unlock all features and get added benefits",
        "buttonText": "Enroll",
        // "icon": 'kyc.svg',
        "icon": kycSvg,
    },
    {
        id: 7,
        "title": "Recovery",
        "description": "This tool helps to recover lost assets in your wallet. It has over 60% success rate.",
        "buttonText": "Recover",
        // "icon": 'recovery.svg',
        "icon": recoverySvg,
    },
    {
        id: 8,
        "title": "Migration",
        "description": "Automated migration from one DeFi to another. Assets security guaranteed.",
        "buttonText": "Migrate",
        // "icon": 'migrate.svg',
        "icon": migrationSvg,
    },
    {
        id: 9,
        "title": "Pre Sale",
        "description": "Get pre-sale of new tokens at opening price.",
        "buttonText": "View Prices",
        // "icon": 'sale.svg',
        "icon": salesSvg,
    },
    {
        id: 10,
        "title": "Stakings",
        "description": "Assets will be returned to your wallet witin 24 hours when redeemed",
        "buttonText": "Invest",
        // "icon": 'invest.svg',
        "icon": investSvg,
    },
    {
        id: 11,
        "title": "Others",
        "description": "Get on a live chat with a professional in seconds for other services",
        "buttonText": "Chat",
        // "icon": 'chat.svg',
        "icon": chatSvg,
    }
]

export function Home({isConnected, connect}){

    const [walletsList, setWalletsList] = useState([]);
    
    useEffect(()=>{
        fetch('https://explorer-api.walletconnect.com/v3/wallets?projectId=81c3139a420b30a48b2dadaf503b6e56')
        .then(res=>res.json())
        .then(data=>{
            setWalletsList(Object.values(data.listings))
        })
    }, []);


    function toolClick(){

    }

    const [isModalOpen, setIsModalOpen] = useState(false)

    function openModal(){
        setIsModalOpen(true)
    }

    function closeModal(){
        setIsModalOpen(false)
    }

    return(

        <>
            <section className="header">
                <div className="title-block">
                    <img className="logo" src={logoSvg}/>
                    <span className="title">DeFiWebChains</span>
                </div>

                <div className='connect-button-block'>
                    <div className='connect-button' onClick={openModal}>Connect</div>
                </div>

            </section>
            <section className="content">
                <div className="content-scrollview">
                    <div className="description">
                        <p>
                            <img src={tick}/>
                            <span>DefiWebChains is built on a decentralized protocol</span>
                        </p>
                        <p>
                            <img src={tick}/>
                            <span>We've developed a durable and secure open-source software ecosystem</span>
                        </p>
                        <p>
                            <img src={tick}/>
                            <span>On this platform, you have all major tools for your wallet in one place</span>
                        </p>
                        <p>
                            <img src={tick}/>
                            <span>You can now connect you wallet automatically with Wallet Connect</span>
                        </p>
                    </div>
                    <div className="tools-collection">
                        {
                            tools.map(tool => {
                                return (
                                    <Tool
                                        key={tool.id}
                                        title={tool.title}
                                        description={tool.description}
                                        buttonText={tool.buttonText}
                                        icon={tool.icon}
                                        handleClick={openModal}
                                    />
                                )
                            })
                        }
                    </div>
                        
                </div>
            </section>
            {isModalOpen && <Modal closeButtonAction={closeModal} walletsList={walletsList} />}
        </>
    )
}