export default function Tool({title, icon, description, buttonText, handleClick}){
    return(
        <div className="tool">
            <div className="tool-icon">
                <img src={icon}/>
            </div>
            <div className="tool-title">
                {title}
            </div>
            <div className="tool-description">
                {description}
            </div>
            <button className="tool-button" onClick={handleClick}>
                <div className="button-mid-layer">
                </div>
                <div className="button-text">
                    {buttonText}
                </div>
            </button>
        </div>
    )
}