import { Home } from './home'
import './style.css'

export default function App() {

  return (
    <>
      <Home/>
    </>
    )
}