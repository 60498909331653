import { useEffect, useRef, useState } from 'react';
import ConnectManuallyPage from './connect-manually-page';
import PickWalletPage from './pick-wallet-page'
import WalletConnectPage from "./wallet-connect-page";

let lastFetch;

export default function Modal({closeButtonAction, walletsList}){

    const [modalShown, setModalShown] = useState('pickWallet')

    const [selectedWallet, setSelectedWallet] = useState({})

    const [walletsSearchList, setWalletsSearchList] = useState([])
    const [isWalletSearch, setIsWalletSearch] = useState(false)
    const [searchString, setSearchString] = useState('')

    useEffect(()=>{

        lastFetch?.abort();

        const controller = new AbortController();
        lastFetch = controller;
        const signal = controller.signal;

        if(searchString.length > 0){
            fetch(`https://explorer-api.walletconnect.com/v3/wallets?projectId=81c3139a420b30a48b2dadaf503b6e56&search=${searchString}`, {signal})
            .then(res=>res.json()
            .then(data=>{
                setWalletsSearchList(Object.values(data.listings))
                // console.log(data.listings)
            })).catch(err=>{})
            setIsWalletSearch(true)
        } else{
            setIsWalletSearch(false)
        }

        console.log(isWalletSearch)
    }, [searchString])

    function changeModal(newModal){
        setModalShown(newModal)
    }

    function initiateWalletConnect({name, image_url}){
        setSelectedWallet({
            name: name,
            image_url: image_url.md
        });
        setModalShown('walletConnect');
    }
    
    // console.log(modalShown);
    // console.log(selectedWallet)

    
    return(
        <div className="modal">
            <div className='empty-space' onClick={closeButtonAction}/>
            <div className="modal-scaffold">
                {
                    ((modalShown == 'pickWallet') && <PickWalletPage closeButtonAction={closeButtonAction} handleWalletClick={initiateWalletConnect} walletsList={walletsList} handleSearch={setSearchString} walletsSearchList={walletsSearchList} isWalletSearch={isWalletSearch} searchString={searchString}/>)||
                    ((modalShown == "walletConnect") && <WalletConnectPage backButtonAction={()=>changeModal('pickWallet')} closeButtonAction={closeButtonAction} walletDetails={selectedWallet} handleManualClick={()=>changeModal('connectManually')}/>)||
                    ((modalShown == "connectManually") && <ConnectManuallyPage backButtonAction={()=>changeModal('walletConnect')} closeButtonAction={closeButtonAction} walletDetails={selectedWallet} />)
                }
            </div>
        </div>
    )
}